import { createContext, useContext } from 'react';

import { FeatureFlagResult } from '@/core/types/featureFlag.type';

export interface FFContextType {
  featureFlag?: FeatureFlagResult;
}

export const FFContext = createContext<FFContextType | null>(null);

export const useFFContext = () => useContext(FFContext) as FFContextType;
