import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { ApiVersions } from '@/core/lib/fetch';
import api from '@/core/lib/fetch/api';
import { type UsersContactForm, type UsersContactsResult, usersContactsResultSchema } from '@/core/types/contact';
import type { UsersHasControlResult } from '@/core/types/control';
import { usersHasControlResultSchema } from '@/core/types/control';
import type { UsersChangePasswordForm, UsersChangePasswordResult, UsersDeleteAccountForm, UsersPrivateProfilResult } from '@/core/types/profile';
import { usersPrivateProfilResultSchema, usersPublicProfilResultSchema } from '@/core/types/profile';
import type { UserControlCguForm, UserControlEmailCheckForm, UserControlResult, UserControlRsForm, UserControlUsernameForm } from '@/core/types/users';
import { userControlResultSchema } from '@/core/types/users';

const getUrl = () => valuesFromProcess[ACTUAL_ENV as Environment]?.DONNONS_API_URL;

const query = {
  get: async (token: string) =>
    api.get<UsersPrivateProfilResult>({
      url: `${getUrl()}${ApiVersions.V1}/user`,
      schema: usersPrivateProfilResultSchema,
      token,
    }),
  control: async (token: string) =>
    api.get<UserControlResult>({
      url: `${getUrl()}${ApiVersions.V1}/user/control`,
      schema: userControlResultSchema,
      token,
    }),
  contacts: async (token: string) =>
    api.get<UsersContactsResult>({
      url: `${getUrl()}${ApiVersions.V1}/user/contacts`,
      schema: usersContactsResultSchema,
      token,
    }),
  user: async (uuid: string, token?: string) =>
    api.get<UsersPrivateProfilResult>({
      url: `${getUrl()}${ApiVersions.V1}/user/${uuid}`,
      schema: usersPublicProfilResultSchema,
      token,
    }),
};

export interface MsgParams {
  push: boolean;
  email: boolean;
}

const mutation = {
  block: async (uuid: string, token: string) =>
    api.post<null, UsersContactForm>({
      url: `${getUrl()}${ApiVersions.V1}/user/contact/${uuid}`,
      token,
      data: { type: 2 } satisfies UsersContactForm,
    }),
  unblock: async (uuid: string, token: string) =>
    api.destroy<null>({
      url: `${getUrl()}${ApiVersions.V1}/user/contact/${uuid}`,
      token,
      data: undefined,
    }),
  newsletter: async (newsletter: boolean, token: string) =>
    api.patch<null, { newsletter: boolean }>({
      url: `${getUrl()}${ApiVersions.V1}/user`,
      token,
      data: {
        newsletter,
      },
    }),
  push: async (push: boolean, token: string) =>
    api.patch<null, { push: boolean }>({
      url: `${getUrl()}${ApiVersions.V1}/user`,
      token,
      data: {
        push,
      },
    }),
  notifications: async ({ push, newsletter }: { push: boolean; newsletter: boolean }, token: string) =>
    api.patch<null, { push: boolean; newsletter: boolean }>({
      url: `${getUrl()}${ApiVersions.V1}/user`,
      token,
      data: {
        push,
        newsletter,
      },
    }),
  msgPush: async (push: boolean, token: string) =>
    api.patch<null, { msg_push: boolean }>({
      url: `${getUrl()}${ApiVersions.V1}/user`,
      token,
      data: {
        msg_push: push,
      },
    }),
  msgEmail: async (email: boolean, token: string) =>
    api.patch<null, { msg_email: boolean }>({
      url: `${getUrl()}${ApiVersions.V1}/user`,
      token,
      data: {
        msg_email: email,
      },
    }),
  accountLifePush: async (life_push: boolean, token: string) =>
    api.patch<null, { life_push: boolean }>({
      url: `${getUrl()}${ApiVersions.V1}/user`,
      token,
      data: {
        life_push,
      },
    }),
  accountLifeEmail: async (life_email: boolean, token: string) =>
    api.patch<null, { life_email: boolean }>({
      url: `${getUrl()}${ApiVersions.V1}/user`,
      token,
      data: {
        life_email,
      },
    }),
  deleteAccount: async (data: UsersDeleteAccountForm, token: string) => {
    const params = new URLSearchParams();
    Object.entries(data).forEach(([key, value]) => {
      if (key !== 'details' && value) {
        params.append('reasons[]', key);
      }
      if (key === 'details' && value && data.details) {
        params.append('other', data.details);
      }
    });
    return api.destroy<null>({
      url: `${getUrl()}${ApiVersions.V1}/user?${params.toString()}`,
      token,
      data: undefined,
    });
  },
  updatePassword: async (data: UsersChangePasswordForm, token: string) =>
    api.put<UsersChangePasswordResult, UsersChangePasswordForm>({
      url: `${getUrl()}${ApiVersions.V1}/user/password`,
      token,
      data,
    }),
  control: {
    cgu: async (token: string) =>
      api.put<UsersHasControlResult, UserControlCguForm>({
        url: `${getUrl()}${ApiVersions.V1}/user/control/cgu`,
        schema: usersHasControlResultSchema,
        token,
        data: { cgu: true },
      }),
    delete: async (token: string) =>
      api.put<UsersHasControlResult>({
        url: `${getUrl()}${ApiVersions.V1}/user/control/delete`,
        schema: usersHasControlResultSchema,
        token,
        data: undefined,
      }),
    emailCheck: async (otp: string, token: string) =>
      api.put<UsersHasControlResult, UserControlEmailCheckForm>({
        url: `${getUrl()}${ApiVersions.V1}/user/control/email_check`,
        schema: usersHasControlResultSchema,
        token,
        data: { otp },
      }),
    rs: async (data: UserControlRsForm, token: string) =>
      api.put<UsersHasControlResult, UserControlRsForm>({
        url: `${getUrl()}${ApiVersions.V1}/user/control/rs`,
        schema: usersHasControlResultSchema,
        token,
        data,
      }),
    username: async (data: UserControlUsernameForm, token: string) =>
      api.put<UsersHasControlResult, UserControlUsernameForm>({
        url: `${getUrl()}${ApiVersions.V1}/user/control/username`,
        schema: usersHasControlResultSchema,
        token,
        data,
      }),
  },
};

export default { query, mutation };
