import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { ApiVersions } from '@/core/lib/fetch';
import api from '@/core/lib/fetch/api';
import { FeatureFlagResult } from '@/core/types/featureFlag.type';

const getUrl = () => valuesFromProcess[ACTUAL_ENV as Environment]?.DONNONS_API_URL;

const featureFlagQuery = {
  get: () => api.get<FeatureFlagResult>({ url: `${getUrl()}${ApiVersions.V1}/params/front` }),
};

export default { featureFlagQuery };
