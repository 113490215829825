import React, { useMemo } from 'react';

import { FFContext } from '@/core/lib/ff/ff.context';
import Query from '@/core/lib/new-architecture/query';

const FFProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data: featureFlag } = Query.featureFlagQuery.useFeatureFlagQuery();

  const value = useMemo(
    () => ({
      featureFlag,
    }),
    [featureFlag],
  );

  return <FFContext.Provider value={value}>{children}</FFContext.Provider>;
};

export default FFProvider;
