import useQuery from '@/core/hooks/api/useQuery';
import Api from '@/core/lib/new-architecture/api';

const featureFlagKeys = {
  base: ['KEY_TO_PREVENT_COLLISIONS', '/params'] as const,
};

const useFeatureFlagQuery = () =>
  useQuery({
    queryKey: featureFlagKeys.base,
    queryFn: () => Api.query.featureFlag.get(),
  });

export default { useFeatureFlagQuery };
