import { useQueryClient } from '@tanstack/react-query';

import auth from '@/core/lib/new-architecture/query/auth.query';
import categories from '@/core/lib/new-architecture/query/categories.query';
import conversation from '@/core/lib/new-architecture/query/conversation.query';
import conversations from '@/core/lib/new-architecture/query/conversations.query';
import documents from '@/core/lib/new-architecture/query/documents.query';
import donation from '@/core/lib/new-architecture/query/donation.query';
import donations from '@/core/lib/new-architecture/query/donations.query';
import featureFlagQuery from '@/core/lib/new-architecture/query/featureFlag.query';
import followedDonations from '@/core/lib/new-architecture/query/followedDonations.query';
import home from '@/core/lib/new-architecture/query/home.query';
import locs from '@/core/lib/new-architecture/query/locs.query';
import push from '@/core/lib/new-architecture/query/push.query';
import savedSearch from '@/core/lib/new-architecture/query/savedSearch.query';
import userDonation from '@/core/lib/new-architecture/query/user.donation.query';
import userDonations from '@/core/lib/new-architecture/query/user.donations.query';
import user from '@/core/lib/new-architecture/query/user.query';

const useReset = () => {
  const queryClient = useQueryClient();
  const reset = () => queryClient.resetQueries();
  return { reset };
};

const Query = {
  auth,
  donation,
  donations,
  followedDonations,
  user: {
    ...user,
    donation: userDonation,
    donations: userDonations,
  },
  categories,
  push,
  locs,
  documents,
  conversation,
  conversations,
  savedSearch,
  home,
  useReset,
  featureFlagQuery,
};

export default Query;
